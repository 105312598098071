import { Badge, Box, Center, createStyles, Group, Popover, Stack, Text } from '@mantine/core';
// import { get, set } from 'idb-keyval';
import React, { useCallback, useEffect, useRef, useState } from 'react';
// import { ApprovalEventTypes } from '../ApprovalModule';
import CaptureToolbar from './CaptureToobar';
import ResolverImage from '../resolverImage/ResolverImage';
import { logger } from 'utils/logger';
import TagTalent from 'core/components/capture/TagTalent';

const useStyles = createStyles((theme, _params, getRef) => ({
    imageContainer: {
        // marginLeft:`-${theme.spacing.lg}px`,
        // marginRight:`-${theme.spacing.lg}px`
        borderBottom: `1px solid ${theme.colors.dark[6]}`,
        paddingTop: theme.spacing.sm,
        paddingLeft: theme.spacing.sm,
        paddingBottom: theme.spacing.sm,
        background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },
    imageClass: {
        ref: getRef('imageClass'),
        border: '3px solid transparent',
        padding: 2,
        maxWidth: '100%',
        objectFit: 'contain',

        // height:'100%',
        // width:'100%',
    },
    imageBadge: {
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: 0,
        borderBottomRightRadius: theme.radius.md,
    },
    imageThumb: {
        borderRadius: theme.radius.sm,
        paddingTop: theme.spacing.xs,
        border: `1px solid transparent`,
        img: {
            userSelect: 'none',
        },
        '&.selected': {
            [`.${getRef('imageClass')}`]: {
                borderColor: theme.colorScheme === 'dark' ? theme.colors.brand[3] : theme.colors.brand[2],
            },
        },
        '&:hover': {
            background: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[1],
        },
        textAlign: 'center',
        alignItems: 'center',
        height: '100%',
    },
}));

const CaptureThumb = React.memo(
    ({ image, onImageEvent, size = 200, selected, badgeLabel, onCaptureChange, showSettings = true, src, style = {}, resolvePath = false, fit = 'container', showPsdLoaded = false }) => {
        // const [imageSrc,setImageSrc] = useState({original:'', url:''});
        const [showTagTalent, setShowTagTalent] = useState(false);

        const [imageSrc, setImageSrc] = useState(src);
        //    const [imageLoaded,setImageLoaded] = useState(false);

        const imgSrc = useRef({ original: '', url: '' });
        const imgRef = useRef();
        const isAliveRef = useRef(true);

        const onClick = useCallback(
            (event) => {
                onImageEvent(CaptureEventType.IMAGE_CLICK, image, event);
            },
            [image, onImageEvent],
        );

        const onDoubleClick = (event) => {
            onImageEvent(CaptureEventType.IMAGE_DBL_CLICK, image, event);
        };
        const { classes } = useStyles();

        const imgClass = selected ? ' selected' : '';

        // useEffect(() => {
        //    if(image){
        //     if(imgSrc.current && image.url && imgSrc.current.original !== image.url){
        //         //getImage();
        //         test();
        //     }else if(!image.keyLo && imgSrc.current.url !== image.url){
        //         imgSrc.current = ({original:image.url, url:image.url});
        //         setImageLoaded(true)
        //     }
        //    }

        // },[image]);

        /* const getImage = useCallback(async() => {

        try{
            const res = await fetchImagePath(`${image.url}`);

            // const blob = await res.blob()
            // const img = new Image()
            // img.src = URL.createObjectURL(blob);
            //setImageSrc(res);
            //imgSrc.current = {url:res};
            console.log(res, " ____PATH", imgSrc.current.url)
            return res;
        }catch(e){
            console.log(e,'failed to fetch path')
        }        
    },[image])
    */
        /* const test = useCallback(() => {

        // check that the image id is not alread in the cache

        get(image.id).then(async (val) => {
            if (val !== undefined) {
                console.log('vlll get', val, imageSrc)
                //if(imageSrc !== val){
                    
                        //imgSrc.current = {url:val};
                setImageSrc(val);
                //}
                    
            } else {
              //let res = await getImage();

              let res = image.previewLo;
              
              console.log('RES@@@@@@@@@@', res,imageSrc)
              set(image.id, res)
              
              if (isAliveRef.current) setImageSrc(res)
            }
          })

    },[image,getImage,imageSrc]) */

        // useEffectOnce(() => {
        //     if (src && src.length) {
        //         setImageSrc(src);
        //     } else {
        //         // test()
        //     }
        //     return () => {
        //         isAliveRef.current = false;
        //     };
        // });

        // issue caching the image so had to move this
        // to src change
        useEffect(() => {
            if (src && src.length) setImageSrc(src);
        }, [src]);

        const handleClick = (e) => {
            if (e.detail === 2) {
                onImageEvent(CaptureEventType.IMAGE_DBL_CLICK, image, e);
            } else {
                onImageEvent(CaptureEventType.IMAGE_CLICK, image, e);
            }
        };

        return (
            <Stack className={`${classes.imageThumb} ${imgClass}`}>
                <div
                    style={{
                        ...style,
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClick={(e) => handleClick(e)}>
                    {badgeLabel ? (
                        <Badge size='xs' variant='gradient' gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }} className={classes.imageBadge}>
                            {badgeLabel}
                        </Badge>
                    ) : null}

                    {/* style={{width:size,height:size}} */}
                    
                    {imageSrc && imageSrc.length ? (
                        <>
                            {resolvePath ? (
                                <ResolverImage src={src} style={{ objectFit: 'container', maxWidth: size, maxHeight: size }} classes={`${classes.imageClass} unhightable`} />
                            ) : (
                                <img
                                    alt={image.originalFilename}
                                    ref={imgRef}
                                    src={imageSrc}
                                    className={`${classes.imageClass} unhightable`}
                                    // fit="contain"

                                    style={{ maxWidth: size, maxHeight: size, objectFit: fit }}
                                />
                            )}
                        </>
                    ) : (
                        <Group style={{ height: '100%', width: '100%', justifyContent: 'center' }}>
                            <Center>
                                {/* <Loader /> */}
                                <Text>Loading..</Text>
                            </Center>
                        </Group>
                    )}

                    {/* <img alt={image.originalFilename} src={image.previewLo} className={`${classes.imageClass} unhightable`}
                // fit="contain"
                height={size}
                sx={{maxWidth:size,maxHeight:size}}
                /> */}
                </div>
                {showSettings && image ? (
                    <TagTalent opened={showTagTalent} onChange={setShowTagTalent} captures={[image]}>
                        <Box position='apart' px={'xs'} py={'xs'} sx={{ opacity: 1 }}>
                            {/* <Text size={'xs'}>{imageSrc} </Text> */}
                            {/* <Checkbox  /> */}
                            <CaptureToolbar
                                size={'sm'}
                                image={image}
                                rating={image.star}
                                onCaptureChange={(type, data, items) => {
                                    onCaptureChange(type, data, [image]);
                                    if (type === CaptureEventType.TAG_TALENT) {
                                        setShowTagTalent(true);
                                    }
                                }}
                                status={image.sak}
                            />
                            <Group mt={'xs'} position={showPsdLoaded?'apart':'center'}>
                                <Text size={'xs'} color='dimmed' className='unhightable'>
                                    {image.originalFilename}
                                </Text>
                                {showPsdLoaded ? (
                                    <>
                                        {image.psdLoaded && image.psdLoaded === 'Y' ? (
                                            <Badge radius={0} size='sm'>
                                                PSD
                                            </Badge>
                                        ) : (
                                            <Badge radius={0} opacity={0.3} color='dark' size='sm'>
                                                PSD
                                            </Badge>
                                        )}
                                    </>
                                ) : null}
                            </Group>
                        </Box>
                    </TagTalent>
                ) : (
                    <Box position='apart' px={'xs'} sx={{ opacity: 1 }}>
                        {image ? (
                            <Text size={'xs'} color='dimmed' className='unhightable'>
                                {image.name}
                            </Text>
                        ) : null}
                    </Box>
                )}
            </Stack>
        );
    },
);

function isEqual(prevProps, nextProps) {
    return false;
}

export default CaptureThumb;

export const CaptureEventType = {
    IMAGE_CLICK: 'imageClick',
    IMAGE_DBL_CLICK: 'imageDblClick',
    RATING_CHANGE: 'ratingChange',
    STATUS_CHANGE: 'statusChange',
    DOWNLOAD: 'download',
    ASSIGN_TO_JOB: 'assignToSession',
    SHOW_IN_CAPTURE_COMMENTS: 'showInCaptureComments',
    SHOW_IN_CAPTURE_SINGLE: 'showInCaptureSingle',
    SHOW_IN_CAPTURE: 'showInCapture',
    CREATE_TASK: 'captureCreatTask',
    BATCH_CREATE_TASK: 'batchCaptureCreatTask',
    APPLY_STYLE_INFO: 'captureApplyStyleInfo',
    TAG_TALENT: 'captureTagTalent',
    ARCHIVE: 'captureArchive',
    UNARCHIVE: 'captureUnArchive',
};

export const CaptureStatus = {
    APPROVED: 'approved',
    REJECTED: 'rejected',
};
