import { CSS } from '@dnd-kit/utilities';
import { IconCamera, IconGripVertical } from '@tabler/icons';
import SampleDragBox from 'modules/Warehouse/features/outfitter/SampleDragBox';
import React, { useEffect, useRef, useState } from 'react';
// import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from 'utils/constants';
import { logger } from 'utils/logger';
import GuideShot from './GuideShot';
// import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DndContext, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { useDrag, useDrop } from 'react-dnd';
import { useHover } from '@mantine/hooks';
import { getCategoryShotFile } from 'services/guideService';
import ResolverImage from 'core/components/resolverImage/ResolverImage';
import { ListViewOption } from 'core/components/listToggle/listToggleControl';

const { createStyles, Card, Text, Title, Image, Badge, SimpleGrid, Group, useMantineTheme, Box, Center, Button, Tooltip, Alert, Divider } = require('@mantine/core');

const useStyles = createStyles((theme, _params, getRef) => ({
    grip: {
        ref: getRef('grip'),
        opacity: 0,
        top: 15,
        position: 'absolute',
        zIndex: 10,
        color: theme.colors.white,
        paddingLeft: 10,
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        height: 26,
        alignItems: 'center',
        transition: 'all .2s ease-in-out',
    },
    seqNum: {
        ref: getRef('seqNum'),
        opacity: 1,
        transition: 'opacity .2s ease-in-out',
    },
    draggableItem: {
        [`&:hover .${getRef('grip')}`]: {
            opacity: 1,
            top: 0,
        },
        [`&:hover .${getRef('seqNum')}`]: {
            opacity: 0,
        },
        [`&:hover .${getRef('imageBadge')}`]: {
            background: `${theme.colors.brand[4]} !important`,
        },
    },
    card: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        '.cardSection': {
            textAlign: 'center',
            // paddingTop:theme.spacing.xl,
            // paddingBottom:theme.spacing.xl,
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
        },
    },
    label: {
        textTransform: 'uppercase',
        fontSize: theme.fontSizes.xs,
        fontWeight: 700,
    },
    image: {
        objectFit: 'contain',
        borderRadius: theme.radius.sm,
        overflow: 'hidden',
    },
    requireBadge: {
        position: 'absolute',
        top: 10,
        right: 10,
        zIndex: 998,
        backgroundColor: theme.colors.brand[4],
        color: theme.colorScheme === 'dark' ? theme.white : theme.white,
    },
    gripWrapper: {
        opacity: 1,
        overflow: 'hidden',
        height: 26,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 999,
        width: 40,
    },
    imageBadge: {
        ref: getRef('imageBadge'),
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: 0,
        borderBottomRightRadius: theme.radius.md,
        zIndex: 9,
        // background:'none'
    },
    seqTitle: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.white,
        position: 'absolute',
    },
    preview: {
        '.overlay': {
            opacity: 0,
        },
        '&:hover': {
            '.overlay': {
                opacity: 0.5,
            },
            '.btn-clear': {
                opacity: 1,
                marginTop: 0,
            },
        },
        '.btn-clear': {
            opacity: 0,
            marginTop: 10,
            transition: 'all .2s ease-in-out',
        },
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: theme.spacing.sm,
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        // paddingBottom: theme.spacing.sm,
        marginTop: theme.spacing.md,
        marginLeft: -38,
        marginRight: -16,
        borderTop: theme.colorScheme === 'dark' ? `1px solid ${theme.colors.dark[4]}` : `1px solid ${theme.colors.gray[4]}`,
    },
    title: {
        // fontFamily: Greycliff CF, var(--mantine-font-family),
        // lineHeight: 1,
    },
}));

const GuideCategory = ({
    channel,
    category,
    allowAssignment = false,
    onItemDrop,
    fullscreen = false,
    editable = false,
    onChange,
    guide,
    breakpoints = [
        { maxWidth: 5000, cols: fullscreen ? 5 : 3, spacing: 'md' },
        { maxWidth: 1800, cols: fullscreen ? 4 : 2, spacing: 'sm' },
        { maxWidth: 600, cols: 1, spacing: 'sm' },
    ],
}) => {
    const { classes } = useStyles();
    const [shots, setShots] = useState([]);
    const [activeId, setActiveId] = useState(null);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

    function handleDragStart(event) {
        setActiveId(event.active.id);
    }

    useEffect(() => {
        if (category && category.shots) {
            setShots([...category.shots]);
        }
    }, [category]);

    function handleDragEnd(event) {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = shots.findIndex((val) => val.id === active.id);
            const newIndex = shots.findIndex((val) => val.id === over.id);

            let tmpShots = arrayMove(shots, oldIndex, newIndex);

            tmpShots = [...tmpShots.map((shot, idx) => ({ ...shot, seq: (idx + 1).toString() }))];
            onChange({ ...category, shots: tmpShots });

            setShots(tmpShots);
        }

        setActiveId(null);
    }

    function handleDragCancel() {
        setActiveId(null);
    }

    const onShotPropsChange = (shot, e, type) => {
        logger.log(shot, e, type);
        const tmpShots = shots.map((val) => {
            if (val.id === shot.id) {
                let tmpShot = { ...shot };
                tmpShot[type] = e;
                return tmpShot;
            } else {
                return val;
            }
        });

        // setShots([...tmpShots]);
        onChange({ ...category, shots: tmpShots });
        // update the shot
    };

    const onShotRemoveClick = (shot) => {
        const data = shots.filter((val) => {
            if (shot.id !== val.id) {
                return val;
            }
        });
        const tmpShots = data.map((shot, idx) => ({ ...shot, seq: idx + 1 }));

        onChange({ ...category, shots: tmpShots });
    };
    return (
        // <SimpleGrid
        //     // cols={4}
        //     spacing='lg'
        //     breakpoints={breakpoints}
        //     >
        //     {category.shots.map((shot, index) => (
        //         <React.Fragment key={index}>
        //             {allowAssignment ? (
        //                 <DroppableShot shot={shot} key={index} classes={classes} index={index} allowAssignment={allowAssignment} onItemDrop={onItemDrop} />
        //             ) : (
        //                 <GuideShot editable={true} shot={shot} key={index} classes={classes} index={index} allowAssignment={allowAssignment} />
        //             )}
        //         </React.Fragment>
        //     ))}
        // </SimpleGrid>
        <>
            {editable ? (
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragCancel={handleDragCancel}>
                    <SortableContext items={shots} strategy={rectSortingStrategy}>
                        <SimpleGrid cols={4} spacing='xl' breakpoints={breakpoints}>
                            {shots.map((shot, index) => (
                                <SortableShot
                                    classes={classes}
                                    value={shot}
                                    key={shot.id}
                                    index={index}
                                    editable={editable}
                                    category={category}
                                    guide={guide}
                                    onShotPropsChange={onShotPropsChange}
                                    onShotRemoveClick={onShotRemoveClick}
                                />
                            ))}
                        </SimpleGrid>
                    </SortableContext>

                    {/* <DragOverlay adjustScale={true}>{activeId ? <GuideShot  : null}</DragOverlay> */}
                </DndContext>
            ) : (
                <SimpleGrid
                    // cols={4}
                    spacing='lg'
                    breakpoints={breakpoints}>
                    {category.shots?.map((shot, index) => (
                        <React.Fragment key={index}>
                            {allowAssignment ? (
                                <DroppableShot shot={shot} channel={channel} key={index} classes={classes} index={index} allowAssignment={allowAssignment} onItemDrop={onItemDrop} />
                            ) : (
                                <GuideShot category={category} guide={guide} editable={false} shot={shot} key={index} classes={classes} index={index} allowAssignment={allowAssignment} />
                            )}
                        </React.Fragment>
                    ))}
                </SimpleGrid>
            )}
        </>
    );
};

export default GuideCategory;

export const DroppableShot = (props) => {
    const { classes, shot, index, onItemDrop, channel, resolve, displayStyle = 'assign', showMetaInfo = true, sequenceNumberLabel, shotLabel, listView } = props;
    const theme = useMantineTheme();
    const [shotImage, setShotImage] = useState();
    const [shotURL, setShotURL] = useState('');

    // const frame = {id,label,items,width,height,index};
    useEffect(() => {
        if (shot.referenceUrl !== shotURL) {
            getCategoryShotFile(shot)
                .then((response) => response.blob())
                .then((blob) => {
                    const url = URL.createObjectURL(blob);
                    setShotImage(url);
                    setShotURL(shot.referenceUrl);
                });
        }
    }, [shot]);

    const [{ canDrop, isOver }, dropz] = useDrop(
        () => ({
            accept: [ItemTypes.BOX, ItemTypes.CAPTURE],
            drop: () => {
                return { name: 'dropbin', data: { ...shot } };
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        }),
        [shot],
    ); // used for cache

    const isActive = canDrop && isOver;
    let backgroundColor = theme.colorScheme === 'dark'?theme.colors.dark[7]:theme.colors.gray[2];

    if (isActive) {
        backgroundColor = theme.colors.brand[3];
    } else if (canDrop) {
        backgroundColor =  theme.colorScheme === 'dark'?theme.colors.dark[4]:theme.colors.brand[2];
    }

    // const [size,setSize] = useState({width:width,height:height});

    // On top layout
    // const onResize = (event, {element, size, handle}) => {
    //   setSize({width: size.width, height: size.height});
    // };

    const ref = useRef(null);
    const showChannelWarning = channel && shot.channel !== channel ? true : false;
    const [{ handlerId }, drop] = useDrop({
        accept: [ItemTypes.BOX, ItemTypes.CAPTURE],
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        drop(item, monitor) {
            //onFrameMoved(item);

            logger.log(item, monitor);
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            const MARGIN = 150;
            // Determine rectangle on screen
            const parentNode = ref.current;
            const hoverBoundingRect = parentNode?.getBoundingClientRect();

            logger.log(hoverBoundingRect, parentNode);
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            logger.log('clientOffset: ', clientOffset);
            logger.log('hoverClientY: ', hoverClientY);

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY + MARGIN < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY - MARGIN > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            // moveFrame(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const id = index;
    const [{ isDragging }, drag, preview] = useDrag({
        type: ItemTypes.BOX,
        item: () => {
            return { id, index };
        },
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging(),
            };
        },
    });

    const opacity = isDragging ? 0 : 1;

    drag(drop(ref));

    // logger.log(shot)

    const listMetaDisplay = listView === ListViewOption.LIST && showMetaInfo ? true : false;
    return (
        <Card
            withBorder
            className={classes.card}
            ref={dropz}
            style={{ opacity, height: '100%', width: '100%', display: 'flex', flexDirection: listMetaDisplay ? 'row' : 'column' }}
            data-testid='dustbin'>
            {/*  '-15px 0 -15px -15px' */}
            <Card.Section className={'cardSection'} ref={preview} style={{ margin: listMetaDisplay ? 'inherit' : 'inherit', flexShrink:0 }}>
                <Group align='stretch' noWrap style={{ position: 'relative' }}>
                    {shot.req === 'yes' && !showChannelWarning ? (
                        <Badge size='xs' className={classes.requireBadge}>
                            Required
                        </Badge>
                    ) : null}

                    <div style={{ position: 'relative', width: '50%' }}>
                        <div
                            style={{
                                position: 'absolute',
                                // width: '50%',
                                left: 0,
                                right: 0,
                                bottom: 0,
                                top: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <Title className={classes.seqTitle} order={1}>
                                {sequenceNumberLabel ? sequenceNumberLabel : shot.seq}
                            </Title>
                        </div>

                        <Image fit='contain' style={{ border: '2px solid transparent', padding: 5 }} className={classes.image} src={shotImage} alt={shot.name} height={200} />
                    </div>

                    <Box
                        className='droppable-box'
                        sx={(theme) => ({
                            width: '50%',
                            backgroundColor,
                            flexGrow: '0 !important',
                            display: 'flex',
                            position: 'relative',
                            overflow: 'hidden',
                            borderRadius: theme.radius.sm,
                        })}>
                        <Box
                            p='xl'
                            sx={{
                                opacity: '.5',
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <Center>
                                {shot && shot.capture && shot.capture.previewLo && shot.capture.previewLo.length ? null : (
                                    <div>
                                        <IconCamera size={32} />
                                        <Text>Drop Capture to Assign</Text>
                                    </div>
                                )}
                            </Center>
                        </Box>

                        <SampleDragBox
                            type={ItemTypes.BOX}
                            data={shot}
                            style={{ padding: 5 }}
                            onSampleDrop={(item, value) => {
                                logger.log(item, value);
                                onItemDrop(item, value);
                            }}>
                            {shot.capture ? (
                                <React.Fragment>
                                    {resolve ? (
                                        <ResolverImage className={classes.image} fit='contain' src={shot.capture.previewLo} alt={shot.capture.previewMid} height={200} />
                                    ) : (
                                        <Image className={classes.image} fit='contain' src={shot.capture.previewLo} alt={shot.capture.previewMid} height={200} />
                                    )}
                                </React.Fragment>
                            ) : null}
                        </SampleDragBox>
                    </Box>
                </Group>

                <Badge size='lg' variant='gradient' gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }} className={classes.imageBadge}>
                    {sequenceNumberLabel ? sequenceNumberLabel : shot.seq}
                </Badge>
            </Card.Section>

            <Group position='left' align={'flex-start'} spacing={'xs'} mt={listMetaDisplay ? 0 : 'md'} noWrap sx={{ flexGrow: 1, alignItems: 'stretch' }}>
                {listMetaDisplay ? null : (
                    <div style={{ flexShrink: 0 }}>
                        <Text className={classes.title} size={'sm'}>
                            {sequenceNumberLabel ? sequenceNumberLabel : shot.seq}.
                        </Text>
                    </div>
                )}

                {displayStyle && displayStyle === 'distribution' ? (
                    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', marginLeft: listView && listView === ListViewOption.LIST && showMetaInfo ? 20 : 0 }}>
                        <Group position='apart' noWrap sx={{ marginTop: -2 }}>
                            <Text weight={500} size={'sm'} truncate>
                                {shotLabel ? shotLabel : `${shot.capture?.stylePart}-${shot.capture?.assignedSequence}.jpg`}
                            </Text>
                            <div>
                                <Badge color='green' variant='light'>
                                    {shot.capture?.status}
                                </Badge>
                            </div>
                        </Group>

                        {showMetaInfo ? (
                            <>
                                <Divider mt='xs' />
                                <Card.Section py='md' mx={0}>
                                    <Text className={classes.title} size={'sm'} weight={700}>
                                        {shot.label}
                                    </Text>
                                    <Text className={classes.title} size={'sm'} sx={{ flexGrow: 1 }} color={shot.notes ? '' : 'dimmed'}>
                                        {shot.notes ? shot.notes : 'No Notes'}
                                    </Text>
                                </Card.Section>
                                <Divider />
                                <Card.Section py='md' mx={0} style={{ display: 'flex', flexDirection: listMetaDisplay ? 'row' : 'column', gap: listMetaDisplay?30:15 }}>
                                    <Box mt={listMetaDisplay ? 0 : 'mt'}>
                                        <Text size='xs' c='dimmed'>
                                            Style Guide
                                        </Text>

                                        <Group>
                                            <Text size={'sm'}>{shot.capture.guide?.name}</Text>
                                        </Group>
                                    </Box>

                                    <Box mt={listMetaDisplay ? 0 : 0}>
                                        <Text size='xs' c='dimmed'>
                                            Category
                                        </Text>

                                        <Group>
                                            <Text size={'sm'}>{shot.capture.guideCategory?.name}</Text>
                                        </Group>
                                    </Box>

                                    <Box mt={listMetaDisplay ? 0 : 0}>
                                        <Text size='xs' c='dimmed'>
                                            Season
                                        </Text>

                                        <Group>
                                            <Text size={'sm'}>{shot.capture.seasonCode}</Text>
                                        </Group>
                                    </Box>

                                    <Box mt={listMetaDisplay ? 0 : 0}>
                                        <Text size='xs' c='dimmed'>
                                            Session
                                        </Text>

                                        <Group>
                                            <Text size={'sm'}>{shot.capture.sessionCode}</Text>
                                        </Group>
                                    </Box>
                                </Card.Section>
                            </>
                        ) : null}
                    </div>
                ) : (
                    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                        <Group position='apart' mb={'sm'} noWrap align='start'>
                            <Text className={classes.title} size={'sm'} weight={700}>
                                {shot.label}
                            </Text>
                            {/* <div>
                            <Badge>{shot.channel}</Badge>
                        </div> */}
                        </Group>
                        <Text className={classes.title} size={'sm'} sx={{ flexGrow: 1 }} color={shot.notes ? '' : 'dimmed'}>
                            {shot.notes ? shot.notes : 'No Notes'}
                        </Text>
                        <Group className={classes.footer}>
                            <Group spacing={'xs'}>
                                <Text size='xs' color='dimmed'>
                                    Tag:
                                </Text>
                                <Text fw={500} size='sm' color={shot.tag ? '' : 'dimmed'}>
                                    {shot.tag && shot.tag.length ? shot.tag : 'None'}
                                </Text>
                            </Group>

                            <div>
                                {/* <Text size='xs' color='dimmed'>
                                Channel
                            </Text> */}
                                <Badge>{shot.channel}</Badge>
                            </div>
                        </Group>
                    </div>
                )}
            </Group>
            {showChannelWarning ? (
                <div style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, background: 'rgba(37,38,43,.9)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Alert color='gray' sx={{ textAlign: 'center' }}>
                        Not required for session channel &nbsp;
                        <Badge>{channel}</Badge>
                    </Alert>
                </div>
            ) : null}
        </Card>
    );
};

export const SortableShot = (props) => {
    const { onShotRemoveClick, onShotPropsChange, editable, ...rest } = props;
    const sortable = useSortable({ id: props.value.id });
    const { attributes, listeners, isDragging, setNodeRef, transform, transition } = sortable;
    const { hovered, ref } = useHover();

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        position: 'relative',
    };

    return (
        <div ref={setNodeRef} style={style} {...rest}>
            <div ref={ref} className={props.classes.draggableItem}>
                <GuideShot editable={props.editable} shot={props.value} classes={props.classes} allowAssignment={false} onChange={props.onShotPropsChange} onRemoveClick={onShotRemoveClick} />
                <Tooltip label='Drag to sort'>
                    <Button
                        size='xs'
                        variant='subtle'
                        {...listeners}
                        {...attributes}
                        sx={{
                            position: 'absolute',
                            top: 1,
                            left: 1,
                            width: 30,
                            zIndex: 999,
                            borderBottomRightRadius: 0,
                            opacity: hovered ? 1 : 1,
                            background: 'transparent',
                            ':hover': { backgroundColor: 'transparent', cursor: 'move' },
                        }}>
                        <IconGripVertical size={14} color='white' />
                    </Button>
                </Tooltip>
            </div>
        </div>
    );
};
