import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Image, Skeleton, Text } from '@mantine/core';
import { getImageFile } from 'utils/helper';

// Simple in-memory cache
const imageCache = new Map();

const ResolverImage = React.memo(({ src = '', onLoaded, width = '100%', height = '100%', classes = {}, alt = '', radius = 0, fit = 'cover' }) => {
    const [imageState, setImageState] = useState({
        url: '',
        isLoading: true,
        error: null,
    });

    const cacheKey = useMemo(() => src, [src]);

    const loadImage = useCallback(async () => {
        if (!src) return;

        setImageState((prev) => ({ ...prev, isLoading: true }));

        try {
            let imageUrl;

            if (imageCache.has(cacheKey)) {
                imageUrl = imageCache.get(cacheKey);
            } else {
                const response = await getImageFile(src);
                const blob = await response.blob();
                imageUrl = URL.createObjectURL(blob);
                imageCache.set(cacheKey, imageUrl);
            }

            setImageState({
                url: imageUrl,
                isLoading: false,
                error: null,
            });

            if (onLoaded) onLoaded(imageUrl);
        } catch (error) {
            console.error('Error loading image:', error);
            setImageState({
                url: '',
                isLoading: false,
                error: 'Failed to load image',
            });
        }
    }, [src, cacheKey, onLoaded]);

    useEffect(() => {
        loadImage();
    }, [loadImage]);

    if (imageState.isLoading) {
        return <Skeleton width={width} height={height} />;
    }

    if (imageState.error) {
        return <div><Text size={'sm'} color='dimmed'>Error: {imageState.error}</Text></div>;
    }

    return <Image fit={fit} className={classes} src={imageState.url} alt={alt} radius={radius} height={height} width={width} withPlaceholder />;
});

ResolverImage.displayName = 'ResolverImage';

export default ResolverImage;
