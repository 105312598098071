import { useState } from 'react';
import { ThemeIcon, UnstyledButton, Group, Text, useMantineTheme, Tooltip, createStyles, Box, ActionIcon } from '@mantine/core';
import { useLocation } from 'react-router-dom';
import {
    IconBook,
    IconCalendarEvent,
    IconCamera,
    IconChevronUp,
    IconColorSwatch,
    IconFriends,
    IconLocation,
} from '@tabler/icons';
import { logger } from 'utils/logger';

// interface MainLinkProps {
//   icon: React.ReactNode;
//   color: string;
//   label: string;
// }

const useStyles = createStyles((theme, _params, getRef) => ({
    mainLink: {
       
        '&.open': {
            [`& .${getRef('submenu')}`]: {
                paddingLeft: 15,
                opacity: 0,
                maxHeight: 0,
            },

            [`& .${getRef('menuArrow')}`]: {
                transform: 'rotate(180deg)',
            },
        },
    },

    button: {
        display: 'block',
        // width: '100%',
        flexGrow: 1,
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.dark[9],
        border: 'none',
        '&:hover': {
            '.themeIcon': {
                color: theme.colors.brand[1],
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.brand[4]:theme.colors.brand[4] ,

                'svg':{
                    transform:'scale(1.1)'
                }
            },
        },
        '.themeIcon': {
            color: theme.colorScheme === 'dark' ?theme.colors.brand[5]:theme.colors.brand[8],
            transition:'all .2s ease-in'
        },
        '&.active': {
            color: theme.colorScheme === 'dark' ?theme.white:theme.colors.dark[9],
            '.themeIcon': {
                color: theme.colors.brand[1],
                backgroundColor: theme.colors.brand[6],
            },
        },
    },
    menuItem: {
        paddingTop: theme.spacing.xs,
        paddingBottom: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        width: '100%',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.dark[7],
        '&:hover': {
            // backgroundColor:theme.colorScheme === 'dark' ? theme.colorScheme : '#F5F7EE',
            color: theme.colorScheme === 'dark' ?theme.colors.brand[1]:theme.colors.dark[9],
        },
    },
    subMenHolder: {
        ref: getRef('submenu'),
        overflow: 'hidden',
        maxHeight: 300,
        transition: 'all .154s',
        opacity: 1,
        marginLeft: 25,
        // paddingLeft: 15,
        borderLeft: `1px dashed ${theme.colors.brand[3]}`,
    },
    menuArrow: {
        ref: getRef('menuArrow'),
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.dark[9],
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.brand[9]:theme.colors.gray[0],
        },
    },
    // themeIcon:{
    //   color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[3],
    // }
}));

function MainLink({ icon, color, label, route, items, onClick, active }) {
    const theme = useMantineTheme();
    const borderColor = active ? theme.colors[`${color}`][8] : 'transparent';
    const { classes } = useStyles(active);
    const [isOpen, setIsOpen] = useState(true);
    const location = useLocation();
    const buttonClick = () => {
        onClick({ label, route, items });
        setIsOpen(!isOpen);
    };

    let activeLink = '';

    return (
        <Box className={isOpen ? `${classes.mainLink} open` : classes.mainLink}>
            <Group>
                <UnstyledButton
                    className={active ? `${classes.button} active` : classes.button}
                    onClick={() => {
                        buttonClick();
                    }}>
                    <Group sx={{ flexGrow: 1 }} spacing={17}>
                        {/* <Tooltip position='right' label={label} withArrow> */}
                            {/* <ThemeIcon color={color} size="lg" style={{borderColor:borderColor}} variant="light">
                {icon}
              </ThemeIcon> */}

                            <ThemeIcon
                                size='lg'
                                radius={'xl'}
                                className='themeIcon'
                                style={{ borderColor: borderColor, borderWidth: 0 }}
                                variant='filled'
                                sx={(theme) => ({
                                    backgroundColor: theme.colorScheme === 'dark' ?theme.colors.brand[9]:theme.colors.brand[2],
                                })}>
                                {icon}
                            </ThemeIcon>
                        {/* </Tooltip> */}
                        <Text size='sm' sx={{ flexGrow: 1, fontWeight: 'bold' }}>
                            {label}
                        </Text>
                    </Group>
                </UnstyledButton>

                {items && items.length ? (
                    <ActionIcon className={classes.menuArrow} onClick={() => setIsOpen(!isOpen)}>
                        <IconChevronUp size={14} />
                    </ActionIcon>
                ) : null}
            </Group>
            
            <Box className={classes.subMenHolder}>
                {items
                    ? items.map((link, idx) => {
                          //  let active = (location.pathname.indexOf(link.route) > -1 && link.route.length >= location.pathname.length)?true:false;
                          const pathname = `${location.pathname}${location.search}`
                          let active = pathname.indexOf(link.route) > -1 ? true : false;
                          if (active && activeLink.length < pathname.length) {
                              activeLink = link.route;
                          }
                          logger.log('active:',activeLink, ' link.route:', link.route)
                          if (activeLink === link.route) {
                              active = true;
                              if(link.route !== '/sessions/tasks' && activeLink === '/sessions' && pathname.indexOf('/sessions/tasks') > -1){
                                active = false;
                              }
                          } else {
                              active = false;
                          }

                          return (
                              <div key={idx}>
                                  <SubMenuLink {...link} key={link.label} active={active ? true : false} onClick={(val) => onClick(val)} />
                              </div>
                          );
                      })
                    : null}
            </Box>
        </Box>
    );
}

const iconSize = 18;
export const menuData = [
    { icon: <IconCamera size={iconSize} strokeWidth={2} />, color: 'blue', label: 'Captures', route: '/approval' },

    {
        icon: <IconColorSwatch size={iconSize} strokeWidth={2} />,
        color: 'grape',
        label: 'Warehouse',
        route: '/warehouse',
        items: [
            // { label: 'Dashboard', route: '/warehouse/dashboard' },
            // { label: 'Feed', route: '/warehouse/feed' },
            { label: 'WIP Styles', route: '/warehouse/styles?status=R' },
            { label: 'Distributed Styles', route: '/warehouse/styles?status=D' },
            { label:'Scans', route:'/warehouse/scans'},
            // {label:'Outfitter', route:'/warehouse/outfitter/create'},
        ],
    },
    {
        icon: <IconCalendarEvent size={iconSize} strokeWidth={2} />,
        color: 'yellow',
        label: 'Events',
        route: '/seasons/dashboard',
        items: [
            { label: 'Dashboard', route: '/seasons/dashboard' },
            { label: 'Events', route: '/seasons' },
            { label: 'Sessions', route: '/sessions' },
            { label: 'All Tasks', route: '/sessions/tasks' },
            // { label: 'Quick Tasks', route: '/sessions/quicktasks' },
        ],
    },

    // { icon: <IconFolders size={iconSize} strokeWidth={2} />, color: 'teal', label: 'Sessions', route:'/sessions', items:[
    //   {label:'Dashboard', route:'/sessions/dashboard'},
    //   {label:'All Sessions', route:'/sessions'},
    //   {label:'All Tasks', route:'/sessions/tasks'},
    //   ]
    // },

    // { icon: <IconLocation size={iconSize} strokeWidth={2} />, color: 'lime', label: 'Locations', route:'/locations',items:[
    //   {label:'All Locations', route:'/locations'},
    //   {label:'Favourites', route:'/locations?filter=favourite'},
    //   ]
    // },

    {
        icon: <IconBook size={iconSize} strokeWidth={2} />,
        color: 'violet',
        label: 'Libraries',
        route: '/libraries',
        items: [
            { icon: <IconColorSwatch size={iconSize} strokeWidth={2} />, color: 'pink', label: 'Style Guides', route: '/styleguides' },
            { icon: <IconFriends size={iconSize} strokeWidth={2} />, color: 'violet', label: 'Talent', route: '/talent' },
            { icon: <IconLocation size={iconSize} strokeWidth={2} />, color: 'pink', label: 'Locations', route: '/locations' },
        ],
    },
];

export function MainLinks({ callBack, selectedMenu }) {
    const onClick = (route) => {
        callBack(route);
    };

    const isActive = (link,menu) =>{
      var active = false;
      if(link && menu){        
  
        if(link.route.indexOf(menu.route) > -1){
          active = true;
        }
        // if no match check the children
        if(!active){
          const item = menu.items?.find(val => val.route === link.route);  
          active = item?true:false;
        }
      }
      
      return active;
    }

    const links = menuData.map((link, key) => {
        return (
            <div key={key}>
                <MainLink {...link} active={isActive(selectedMenu, link)} key={link.label} onClick={(val) => onClick(val)} />
            </div>
        );
    });

    return <div>{links}</div>;
}

function SubMenuLink({ icon, color, label, route, items, onClick, active }) {
    const { classes } = useStyles();
    const buttonClick = () => {
        onClick({ label, route });
    };
    const cls = active ? 'active' : '';

    return (
        <UnstyledButton
            onClick={buttonClick}
            className={[classes.menuItem, cls]}
            sx={(theme) => ({
                borderRadius: 0,
                paddingLeft: 34,
                transition: 'all .2s',
                '&.active': {
                    color: theme.colorScheme === 'dark' ? theme.white : theme.colors.brand[8],
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
                    borderLeft: `3px solid ${theme.colors.teal[9]}`,
                    borderColor: theme.colorScheme === 'dark' ? theme.colors.brand[4] : theme.colors.brand[5],
                },
            })}>
            <Group>
                <Text size='sm'>{label}</Text>
            </Group>
        </UnstyledButton>
    );
}
