import { AnnotationModel, CaptureModel } from 'core/models';
import { Exceptions } from 'utils/constants';
import { getToken, parseObjectTypes } from 'utils/helper';
import { logger } from 'utils/logger';

export const getCapture = async (id, token) => {
    const url = `${process.env.REACT_APP_API_URL}/notes/${id}`;

    const formData = JSON.stringify({});

    logger.log(' GETTING SCAN DATA: ', formData);
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Error getting capture');
        })
        .then((data) => {
            logger.log(data);
            const items = data.Items.map((val, idx) => {
                var item = createAnnotationModel(val);
                return item;
            });
            return items;
        })
        .catch((err) => {
            console.error('ERROR getting scan', err);
            throw err;
        });
};

export const updateCapture = async (capture, token) => {
    const url = `${process.env.REACT_APP_API_URL}/studioactions/${capture.actionPart}/${capture.actionSort}`;
    const tmpCapture = { ...capture };

    // remove the action part and sort
    delete tmpCapture.actionPart;
    delete tmpCapture.actionSort;

    const formData = JSON.stringify(tmpCapture);

    logger.log(' UPDATING Capture DATA: ', formData);
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            hgio: getToken(),
        },
        method: 'PUT',
        body: formData,
    })
        .then((response) => {
            logger.log(response);
            if (response) {
                return response.json();
            }
            throw new Error('Error updating captures');
        })
        .then((data) => {
            if (data.__type && data.__type === Exceptions.SERIALISATION) {
                throw new Error('Error updating captures');
            }
            logger.log(data);
            return true;
            //return imgUploadUrl;
        })
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

export const getCaptures = async (criteria, cursor) => {
    // let url = `${process.env.REACT_APP_API_URL}/studioactions/search?`;
    let url = `${process.env.REACT_APP_API_URL}/studioactions/p?`;

    logger.log(criteria, ' ============ CRITERIA');
    // if(criteria.actionPart && criteria.actionPart.length){
    //     url += `machine/${criteria.actionPart}/`;
    // }else if(criteria.sessionId && criteria.sessionId.length){
    //     url += `session/${criteria.sessionId}`;
    // }else if(criteria.unpinned.toString().length){
    //     if(criteria.unpinned)
    //         url += `unpinned/`;
    // }
    if (criteria.sessionId && criteria.sessionId.length) {
        url += `sessionId=${criteria.sessionId}&`;
    }
    if (criteria.client && criteria.client.length) {
        url += `client=${criteria.client}&`;
    }
    if (criteria.star > -1) {
        url += `star=${criteria.star}&`;
    }
    if (criteria.sak > -1) {
        url += `sak=${criteria.sak}&`;
    }
    if (criteria.tags && criteria.tags.length) {
        url += `tags=${criteria.tags.toString()}&`;
    }

    if (criteria.machineId && criteria.machineId.length) {
        url += `machineId=${criteria.machineId}&`;
    }

    if (criteria.catalogue && criteria.catalogue.value) {
        if (criteria.catalogue.value.since) url += `since=${criteria.catalogue.value.since}&`;
        if (criteria.catalogue.value.until) url += `until=${criteria.catalogue.value.until}`;
    }

    if (cursor) {
        url += `&cursor=${cursor}`;
    }

    url += '&limit=100';
    // const formData = JSON.stringify({

    // })

    logger.log(' GETTING Captures DATA: ', url);
    return fetch(url, {
        headers: {
            // 'Content-Type': 'application/json',
            hgio: getToken(),
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Error fetching captures');
        })
        .then((data) => {
            logger.log(data);
            // var items = data.items.map((item,idx) => {
            //     const keys = Object.keys(item);
            //     var newItem = {};
            //     keys.forEach((key,idx) => {
            //         let tmpKey = key.replace(' ','');
            //         tmpKey = key.replace(':','_');
            //         // lowercase the first.
            //         let char = tmpKey.charAt(0).toLowerCase();
            //         let subStr = tmpKey.substring(1,tmpKey.length);
            //         const newKey = `${char}${subStr}`;
            //         newItem[newKey] = item[key]['S'];
            //         newItem.id = `${newItem.actionPart}__${newItem.actionSort}`;
            //     })
            //     return newItem
            // })
            // var items = data.items.map((item, idx) => {
            //     var newItem = new CaptureModel({ ...item });
            //     newItem.id = `${newItem.actionPart}__${newItem.actionSort}`;
            //     return newItem;
            // });
            var items = data.Items.map((item, idx) => {
                var newItem = new CaptureModel({ ...item });
                newItem.previewHi = item.hiURL;
                newItem.previewLo = item.loURL;
                newItem.id = `${newItem.actionPart}__${newItem.actionSort}`;

                return newItem;
            });

            return { items, nextCursor: data['next-cursor'] === 'nil' ? null : data['next-cursor'] };
        })
        .catch((err) => {
            console.error('ERROR getting captures', err);
            throw err;
        });
};

export const getLatestCaptures = async (criteria) => {
    let url = `${process.env.REACT_APP_API_URL}/studioactions/s/latest`;

    logger.log(' GETTING Captures DATA: ', url);
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            hgio: getToken(),
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Error fetching captures');
        })
        .then((data) => {
            logger.log(data);

            var items = data.items.map((item, idx) => {
                var newItem = new CaptureModel({ ...item });
                newItem.id = `${newItem.actionPart}__${newItem.actionSort}`;
                return newItem;
            });

            return items;
        })
        .catch((err) => {
            console.error('ERROR getting captures', err);
            throw err;
        });
};

///////////////
// MARKUPS
///////////////
export const getMarkups = async (id, criteria) => {
    let url = `${process.env.REACT_APP_API_URL}/markup/${id}`;

    logger.log(' GETTING Captures DATA: ', url);
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            hgio: getToken(),
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Error fetching markups');
        })
        .then((data) => {
            logger.log(data);
            const items = data.Items.map((val, idx) => {
                var item = createAnnotationModel(val);
                return item;
            });
            return items;
        })
        .catch((err) => {
            logger.error('ERROR getting markups', err);
            throw err;
        });
};

export const udpateMarkup = async (id, markup, token) => {
    let url = `${process.env.REACT_APP_API_URL}/markup/${id}`;

    if (markup.annSort) {
        url += `?annSort=${markup.annSort}`;
    }

    const formData = JSON.stringify({
        ...markup.payload,
        targetId: id,
    });

    logger.log(id, markup);
    logger.log(' UPDATING NOTE DATA: ', formData);

    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            hgio: getToken(),
        },
        method: 'PUT',
        body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            logger.log(data);
            if (data.__type) {
                throw new Error('Sorry we were unable to save the note. Please try again later.');
            } else {
                var newItem;

                if (data) {
                    var item = data.Attributes;
                    const keys = Object.keys(data.Attributes);
                    newItem = new AnnotationModel();
                    keys.forEach((key, idx) => {
                        let tmpKey = key.replace(' ', '');
                        tmpKey = key.replace(':', '_');
                        // lowercase the first.
                        let char = tmpKey.charAt(0).toLowerCase();
                        let subStr = tmpKey.substring(1, tmpKey.length);
                        const newKey = `${char}${subStr}`;
                        newItem[newKey] = item[key]['S'];
                    });
                } else {
                }

                // newItem.id = `${newItem.sessionId}|${newItem.sessionSort}`;

                logger.log(newItem);
                //moment(newItem.deadline).format('DD-MM-YYYY');;

                return newItem;
            }
            //return imgUploadUrl;
        })
        .catch((err) => {
            console.error('ERROR updating markup', err);
            throw err;
        });
};

export const removeMarkup = async (id, markup, token) => {
    let url = `${process.env.REACT_APP_API_URL}/markup/${id}`;
    const formData = JSON.stringify({
        ...markup,
    });

    logger.log(' DELETING NOTE DATA: ', formData);
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            hgio: getToken(),
        },
        method: 'POST',
        body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Error removing markup');
        })
        .then((data) => {
            logger.log(data);
            //return imgUploadUrl;
        })
        .catch((err) => {
            console.error('ERROR updating markup', err);
            throw err;
        });
};

export const getAggData = async () => {
    let url = `${process.env.REACT_APP_API_URL}/agg`;

    return fetch(url, {
        headers: {
            // 'Content-Type': 'application/json',
            hgio: getToken(),
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            logger.log(data);

            const parsedObject = parseObjectTypes(data);
            const arrayKeys = ['machines'];

            const items = parsedObject.Items.map((val) => {
                var obj = { ...val };
                arrayKeys.forEach((newKey) => {
                    let tmpArr = obj[newKey].split(',');
                    let newVal = tmpArr.map((val) => {
                        let valArr = val.split(':');
                        return valArr.length > 2 ? { label: valArr[0], value: valArr[1] } : val;
                    });

                    obj[newKey] = newVal;
                });
                return obj;
            });

            //items = items.splice(0,50)
            logger.log(items, ' agg data');
            return items[0];
        })
        .catch((err) => {
            console.error('ERROR getting agg data', err);
            throw err;
        });
};

export const getDepartments = async () => {
    let url = `${process.env.REACT_APP_API_URL}/department`;
    return fetch(url, {
        headers: {
            // 'Content-Type': 'application/json',
            hgio: getToken(),
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            logger.log(data);
            return data.departments;
        })
        .catch((err) => {
            console.error('ERROR getting department data', err);
            throw err;
        });
};

const createAnnotationModel = (item) => {
    const keys = Object.keys(item);
    var newItem = new AnnotationModel();
    keys.forEach((key, idx) => {
        let tmpKey = key.replace(' ', '');
        tmpKey = key.replace(':', '_');
        // lowercase the first.
        let char = tmpKey.charAt(0).toLowerCase();
        let subStr = tmpKey.substring(1, tmpKey.length);
        const newKey = `${char}${subStr}`;
        newItem[newKey] = item[key]['S'];
    });

    newItem.payload = JSON.parse(newItem.payload);
    logger.log(newItem);

    return newItem;
};
