import { Box, Center, Container, Divider, Group, SegmentedControl, Switch, Text, Title, useMantineColorScheme } from '@mantine/core';
import { IconMoon, IconSun } from '@tabler/icons';
import { FormInputWrapper } from 'core/components/';
const SettingsModule = () => {
    return (
        <Box
            id='settingsModule'
            sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
                width: '100%',
            })}>
            <Container mt={'xl'}>
                <Box px='lg'>
                    <Group
                        className='main-container-header'
                        position='apart'
                        sx={(theme) => ({
                            borderBottom: `1px solid`,
                            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],
                            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
                        })}>
                        {/* <Crumbline></Crumbline> */}
                        <Box>
                            <Title order={4}>Workspace Settings</Title>
                            <Text size={'sm'}>Manage your workspace</Text>
                        </Box>
                    </Group>

                    <Group className='container-action-bar' sx={{ width: '100%' }} position='apart' height={'100%'} py='xs' mt='sm'>
                        <Box sx={{ width: '100%' }}>
                            <Group position='apart'>
                                <Title className='main-content-heading' order={5} mb='xs'>
                                    Notifications
                                </Title>
                            </Group>

                            <FormInputWrapper labelWidth={200} label='Enable browser notifications' id='input-notifications'>
                                <Group position='right' sx={{ margin: 0 }}>
                                    <Switch defaultChecked></Switch>
                                </Group>
                            </FormInputWrapper>

                            <Divider />

                            <Group position='apart' mt={'xl'}>
                                <Title className='main-content-heading' order={5} mb='xs'>
                                    Theme
                                </Title>
                            </Group>

                            <FormInputWrapper labelWidth={'auto'} label='Set the theme for your workspace' id='input-notifications'>
                                <Group position='right' sx={{ marginTop: -24 }}>
                                    <SegmentedToggle />
                                </Group>
                            </FormInputWrapper>
                            <Text color='dimmed' pt={13} size={'xs'}>
                                OnSet v0.1.32
                            </Text>
                        </Box>
                    </Group>
                </Box>
            </Container>
        </Box>
    );
};

export default SettingsModule;

const SegmentedToggle = () => {
    const { colorScheme, toggleColorScheme } = useMantineColorScheme();

    return (
        <Group position='center' my='xl'>
            <SegmentedControl
                value={colorScheme}
                onChange={(value) => toggleColorScheme(value)}
                data={[
                    {
                        value: 'light',
                        label: (
                            <Center>
                                <IconSun size={16} stroke={1.5} />
                                <Box ml={10}>Light</Box>
                            </Center>
                        ),
                    },
                    {
                        value: 'dark',
                        label: (
                            <Center>
                                <IconMoon size={16} stroke={1.5} />
                                <Box ml={10}>Dark</Box>
                            </Center>
                        ),
                    },
                ]}
            />
        </Group>
    );
};
