import {
    createStyles,
    Group,
    Paper,
    Text,
    ThemeIcon,
    SimpleGrid,
    Container,
    Title,
    Box,
    useMantineTheme,
    Indicator,
} from '@mantine/core';
import { IconArrowDownRight, IconArrowUpRight } from '@tabler/icons';
import { connect, useDispatch } from 'react-redux';
import './CustomCalendar.scss';
import AltCalendar from './AltCalendar';
import { fetchAllTasksByStatus, fetchSessions, fetchTasks } from 'modules/Sessions/sessionSlice';
import { fetchSeasons } from 'modules/Seasons/seasonSlice';
import { useNavigate } from 'react-router-dom';
import { fetchGuides } from 'modules/Guides/guideSlice';
import { useEffectOnce } from 'utils/helper';

const useStyles = createStyles((theme) => ({
    root: {
        //   padding: theme.spacing.xl * 1.5,
        // paddingTop:theme.spacing.xl * 1,
    },

    label: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },
    statPanel: {
        cursor: 'pointer',
        // padding:`30px !important`,
        transition: 'all .2s ease-in-out',
        ':hover': {
            borderColor: theme.colors.brand[4],
            // transform: 'translate(0,-6px)'
            // padding:`10px !important`,
        },
    },
}));

const Dashboard = ({ sessions, seasons, tasks, aggData, user, guides }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useMantineTheme();
    
    /**
     * Effect to fetch the sessions
     */
    useEffectOnce(() => {
        setTimeout(() => {
            dispatch(fetchSessions());
            dispatch(fetchSeasons());
            // dispatch(fetchTasks({}, {}));
            dispatch(fetchAllTasksByStatus({}))
            dispatch(fetchGuides());
        }, 100);
    }, [dispatch]);



    return (
        // <ScrollArea style={{ height: 'calc(100vh - 60px)' }}>
        <Container fluid sx={{display:'flex', flexDirection:'column', height:'100%', overflow:'auto', justifyContent:'space-between'}}>
             
            <Group className='main-container-header' sx={(theme) => ({position:'relative !important',top:'0 !important', minHeight:'auto !important'})}>
                <Title mt={'xl'} order={2}>
                    Events Dashboard
                </Title>
            </Group>
            <StatsGridIcons
                onStatClick={(stat) => navigate(stat.route)}
                data={[
                    { diff: 23, title: 'Events', value: seasons.length, route: '/seasons', color: theme.colors.blue[6] },
                    { diff: 23, title: 'Sessions', value: sessions.length, route: '/sessions', color: theme.colors.grape[6] },
                    { diff: 23, title: 'Tasks', value: tasks.length, route: '/sessions/tasks', color: theme.colors.pink[6] },
                ]}
            />

            <Box sx={{flex:1}} pb={'md'}>
                <Title pt='xl' mt={'xl'} order={4} sx={{position:'sticky', top:0, zIndex:9, background:theme.colorScheme === 'dark'?theme.colors.dark[8]:theme.white}}>
                    Events Schedule
                </Title>
                <AltCalendar user={user} sessions={sessions} seasons={seasons} tasks={tasks} aggData={aggData} guides={guides} />
            </Box>
            
        </Container>
        
    );
};

export function StatsGridIcons({ data, onStatClick }) {
    const { classes } = useStyles();
    const stats = data.map((stat) => {
        const DiffIcon = stat.diff > 0 ? IconArrowUpRight : IconArrowDownRight;
        return (
            <Paper shadow='md' className={classes.statPanel} withBorder p='md' radius='md' key={stat.title} onClick={() => onStatClick(stat)}>
                <Group position='apart'>
                    <div>
                        <Indicator position='middle-start' color={stat.color}>
                            <Text sx={{paddingLeft:12}} color='dimmed' transform='uppercase' weight={700} size='xs' className={classes.label}>
                                {stat.title}
                            </Text>
                        </Indicator>

                        <Text weight={700} size='xl'>
                            {stat.value}
                        </Text>
                    </div>
                    <ThemeIcon color='gray' variant='light' sx={(theme) => ({ color: stat.diff > 0 ? theme.colors.teal[6] : theme.colors.red[6] })} size={38} radius='md'>
                        <DiffIcon size={28} stroke={1.5} />
                    </ThemeIcon>
                </Group>
                {/* <Text color='dimmed' size='sm' mt='md'>
                    <Text component='span' color={stat.diff > 0 ? 'teal' : 'red'} weight={700}>
                        {stat.diff}%
                    </Text>{' '}
                    {stat.diff > 0 ? 'increase' : 'decrease'} compared to last month
                </Text> */}
            </Paper>
        );
    });

    return (
        <div className={classes.root}>
            <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
                {stats}
            </SimpleGrid>
        </div>
    );
}

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        user: state.auth.user,
        sessions: state.sessionReducer.sessions,
        seasons: state.seasonReducer.seasons,
        tasks: state.sessionReducer.tasks,
        guides: state.guideReducer.guides,
        samples: state.warehouse.samples,
        aggData: state.appReducer.aggData,
        success: state.auth.success,
        error: state.auth.error,
    };
};

const mapDispatch = {};

export default connect(mapStateToProps, mapDispatch)(Dashboard);
